import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "gatsby-plugin-intl";
import "./popover.modules.scss";
import box from "../../images/samsung/box.png";
import notebook from "../../images/samsung/notebook.png";

function Popover({ linksVisible }) {
  const intl = useIntl();
  const adld_hk = process.env.GATSBY_ADLD_HK;
  const adld_en = process.env.GATSBY_ADLD_EN;
  const extendedWarrantyPdf_hk = process.env.GATSBY_EXTENDED_WARRANTY_HK;
  const extendedWarrantyPdf_en = process.env.GATSBY_EXTENDED_WARRANTY_EN;
  const adld_Notebook_en = process.env.GATSBY_ADLD_NOTEBOOK_EN;
  const adld_Notebook_hk = process.env.GATSBY_ADLD_NOTEBOOK_HK;
  const adld_Insurance_en = process.env.GATSBY_ADLD_Insurance_EN;
  const adld_Insurance_hk = process.env.GATSBY_ADLD_Insurance_HK;

  return (
    <div className="popover-wrapper">
      {linksVisible && (
        <div className={`cuspopover ${intl.locale}`}>
          {adld_en && (
            <span>
              <a
                href={intl.locale === "en" ? adld_en : adld_hk}
                target="_blank"
                rel="noreferrer"
              >
                {intl.formatMessage({ id: "accidental_liquid_damage" })}
              </a>
              <img
                className="notebook"
                type="notebook1"
                src={notebook}
                alt="notebook"
                width="15"
                style={{ marginLeft: "0.5em" }}
              />
            </span>
          )}
          {extendedWarrantyPdf_en && (
            <span>
              <a
                href={
                  intl.locale === "en"
                    ? extendedWarrantyPdf_en
                    : extendedWarrantyPdf_hk
                }
                target="_blank"
                rel="noreferrer"
              >
                {intl.formatMessage({ id: "extended_battery_warranty" })}
              </a>
              <img
                className="notebook"
                type="notebook2"
                src={notebook}
                alt="notebook"
                width="15"
              />
            </span>
          )}
          {adld_Notebook_en && (
            <span>
              <a
                href={
                  intl.locale === "en" ? adld_Notebook_en : adld_Notebook_hk
                }
                target="_blank"
                rel="noreferrer"
              >
                {intl.formatMessage({
                  id: "accidental_liquid_damage_notebook",
                })}
              </a>
              <img
                className="notebook"
                type="notebook2"
                src={notebook}
                alt="notebook"
                width="15"
              />
            </span>
          )}
          {adld_Insurance_en && (
            <span>
              <a
                href={
                  intl.locale === "en" ? adld_Insurance_en : adld_Insurance_hk
                }
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                {intl.formatMessage({
                  id: "accidental_liquid_damage_insurance_plan",
                })}
              </a>
              <img
                className="notebook"
                type="notebook2"
                src={notebook}
                alt="notebook"
                width="15"
              />
            </span>
          )}
          <img
            src={box}
            type="popover"
            alt="popover arrow"
            width="20"
            height="20"
          />
        </div>
      )}
    </div>
  );
}

Popover.propTypes = {
  linksVisible: PropTypes.bool,
};

export default Popover;
