import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormattedHTMLMessage, useIntl } from 'gatsby-plugin-intl';
import { PARTNER_COUNTRY } from '../../utils/constants';
// import { Link } from "gatsby";

export default function Footer({setPopoverVisible}) {
  const intl = useIntl();
  const [samsungCareLink, setSamsungCareLink] = useState(process.env.GATSBY_SAMSUNG_CARE_PLUS_URL)
  const [samsungPrivacyPolicy, setSamsungPrivacyPolicy] = useState(process.env.GATSBY_PRIVACY_POLICY_BE)
  const isChineseCountry = () => {
    return ['HK', 'TW', 'MO'].includes(process.env.GATSBY_COUNTRY)
  }
  useEffect(() => {
    let currentUrl = document.location.href;
    const language = intl.locale;
    currentUrl = currentUrl.split("/")[0]
    const samsungPrivacyPolicyLink = isChineseCountry() && intl.locale === 'en' ? process.env.GATSBY_PRIVACY_POLICY_EN_BE : process.env.GATSBY_PRIVACY_POLICY_BE
    const samsungCareplusLink = isChineseCountry() && intl.locale === 'en' ? process.env.GATSBY_SAMSUNG_CARE_PLUS_EN_URL : process.env.GATSBY_SAMSUNG_CARE_PLUS_URL
    setSamsungCareLink(samsungCareplusLink)
    process.env.GATSBY_REGION === 'ASIA' ? setSamsungPrivacyPolicy(samsungPrivacyPolicyLink) : setSamsungPrivacyPolicy(`${currentUrl}/portal/termsAndConditions?realm=${process.env.GATSBY_REALM}&language=${language.toUpperCase()}`)
    
  }, [])

  const isPopoverRequired = () => {
    return process.env.GATSBY_COUNTRY === PARTNER_COUNTRY.HK || process.env.GATSBY_COUNTRY === PARTNER_COUNTRY.MO
  }

  return (
    <Row className='footer'>
      <Col md="8" xs="16" className={`mb-1 mt-1 container__body `}>
        <FormattedHTMLMessage id="footerText" />
      </Col>
      <Col md="4" xs="16" className={`mt-1 container__copyright `} style={{textAlign:"end"}} >
        <span >
          <a href={samsungCareLink} target="_blank" rel='noreferrer'> {intl.formatMessage({id: "whatisSamsung"})}</a> | {isPopoverRequired() && (<a onClick={setPopoverVisible} target="_blank" rel='noreferrer'> {intl.formatMessage({id: "terms"})} |</a> )} <a href={samsungPrivacyPolicy} target="_blank" rel='noreferrer'>{intl.formatMessage({id: "privacy"})}</a> 
          {/* {process.env.GATSBY_COUNTRY === PARTNER_COUNTRY.MY && 
            <>| {' '} 
              <Link
                to={`/${intl.locale}/faqs/migration`}
                activeClassName="active"
              >
                {intl.formatMessage({ id: "faqs" })}
              </Link>
            </>
          } */}
        </span>
      </Col>
      
    </Row>
  )
}

Footer.propTypes = {
  setPopoverVisible: PropTypes.func,
};
